.footer_container {
  background: black;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer_body {
  margin: auto;
  width: 95%;
}

.footer_body_details {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.footer_copyright {
  opacity: 0.8;
  font-size: 15px;
}

.footer_socials {
  display: flex;
  margin-left: 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.footer_socials_icon {
  color: var(--color-primary);
  font-size: 1rem;
  line-height: 1.5rem;
}

.footer_socials_icon:hover {
  color: var(--color-primary-hover);
}

/* ============== Media Queries (Medium Devices) ============== */
@media (min-width: 1024px) {
  .footer_body_details {
    margin-top: 0;
    flex-direction: row;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .footer_copyright {
    margin-top: 0.5rem;
  }

  .footer_socials {
    margin-top: 1rem;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .footer_copyright {
    margin-top: 0.5rem;
  }

  .footer_socials {
    margin-top: 1rem;
  }
}
